import { FC, useEffect, useState } from 'react'

import { Grid2 as Grid } from '@mui/material'
import { useLocalStorage, useMediaQuery } from 'usehooks-ts'

import InformerGridCard from 'src/components/InformerGridCard/InformerGridCard'
import InformerMasterHeader from 'src/components/InformerMasterHeader'
import { compileSectionsCards } from 'src/components/InformerSectionListCell/InformerSectionFunctions'
import SearchGroups from 'src/components/Learner/Library/SearchGroups/SearchGroups'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { useAuth } from 'src/Providers'

export const QUERY = gql`
  query FindInformerSectionsQuery($isPinnedToSidebar: Boolean!) {
    informerSectionList: informerSectionsByClientAndPinnedStatusAndUserLevelAccess(
      isPinnedToSidebar: $isPinnedToSidebar
    ) {
      id
      name
      clientId
      order
      membershipGroups {
        id
        membershipGroupId
      }
      informerListCards {
        id
        name
        iconStorageObject {
          id
          downloadUrl
        }
        url
        order
        clientId
        isAll
        isHidden
        isEmbed
        members {
          id
          informerListCardId
          membershipId
        }
        membershipGroups {
          id
          membershipGroupId
          membershipGroup {
            name
          }
        }
      }
    }
  }
`

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'cache-and-network',
})

const EmptyResults: FC<{ heading: string; subheading: string }> = ({
  heading,
  subheading,
}) => {
  return (
    <div className="mt-4 p-5">
      <p className="text-center text-lg text-gray-500">
        {heading}
        <br />
        {subheading}
      </p>
    </div>
  )
}

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  return (
    <div className="px-4" data-item="informer-sections">
      <InformerMasterHeader />
      <EmptyResults
        heading="No Home Sections or Cards found."
        subheading="Contact your admin team to configure your home screen."
      />
    </div>
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({ informerSectionList }) => {
  // Check if mobile
  const isMobile = useMediaQuery('(max-width: 640px)')
  const isTablet = useMediaQuery('(max-width: 1024px)')

  // Get current user data for content filtering
  const { currentUser } = useAuth()

  // Get client and member info - used to save personal preferences
  const clientId = currentUser.parentData.id
  const memberId = currentUser.userData.activeMembershipId

  const [recentCardIds] = useLocalStorage(
    `memberId-${currentUser.membershipData?.id}-recentCardIds`,
    [],
  )

  const [recentCards, setRecentCards] = useState([])

  // Get current user membershipId
  const activeUserMembershipId = currentUser?.userData?.activeMembershipId

  const activeUserMembershipGroups =
    currentUser?.membershipData?.membershipGroups.map((membership) => {
      return {
        id: membership?.id,
        value: membership?.id,
        name: membership?.name,
      }
    })

  // JUST ME SELECTOR
  // Only show cards/sections that are assigned to the member, or assigned to a membership group the member is in.
  // 0 is an unused ID for membershipGroups - should be safe to use
  activeUserMembershipGroups.unshift({
    id: 0,
    value: 0,
    name: 'Just Me',
  })

  // local storage name for group selection
  const localStorageName = `clientId-${clientId}-memberId-${memberId}-home-screen-groups`

  const [selectedmembershipGroupIds, setSelectedmembershipGroupIds] =
    useLocalStorage<number[]>(localStorageName, [])

  // Set HomeLinks filters
  const [searchVal, setSearchVal] = useState<string>('')
  const [selectedGroups, setSelectedGroups] = useState<number[]>(
    selectedmembershipGroupIds,
  )
  const [informerSections, setInformerSections] = useState<any[]>([])
  useEffect(() => {
    setInformerSections(
      compileSectionsCards(
        activeUserMembershipId,
        informerSectionList,
        searchVal,
        selectedGroups,
      ),
    )
  }, [activeUserMembershipId, informerSectionList, searchVal, selectedGroups])
  // Set the sections

  // On searchVal change, re-filter the items
  useEffect(() => {
    setInformerSections(
      compileSectionsCards(
        activeUserMembershipId,
        informerSectionList,
        searchVal,
        selectedGroups,
      ),
    )
  }, [searchVal, selectedGroups])

  useEffect(() => {
    if (recentCardIds?.length > 0) {
      const recentCards = informerSectionList
        .map((section) => {
          return section.informerListCards.filter((card) =>
            recentCardIds.includes(card.id),
          )
        })
        .flat()
      recentCards.sort(function (a, b) {
        return recentCardIds.indexOf(a.id) - recentCardIds.indexOf(b.id)
      })
      setRecentCards(recentCards)
    }
  }, [recentCardIds, informerSectionList])

  useEffect(() => {
    setSelectedmembershipGroupIds(selectedGroups)
  }, [selectedGroups])

  return (
    <div className="bg-gray-50 px-3 md:px-8" data-item="informer-sections">
      <InformerMasterHeader>
        {recentCards.length > 0 &&
          (isMobile ? (
            <Grid container spacing={2}>
              {recentCards.slice(0, 8).map((card) => {
                return (
                  <Grid size={{ xs: 4 }} key={card?.id}>
                    <InformerGridCard
                      gridItem={card}
                      key={card?.id}
                      sectionName="Recently Viewed"
                    />
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <div
              className="grid w-full gap-6"
              style={{
                gridTemplateColumns: `repeat(auto-fill, minmax(${
                  isTablet ? '150px' : '180px'
                }, 1fr))`,
              }}
            >
              {recentCards.slice(0, 8).map((card) => {
                return (
                  <div key={card?.id}>
                    <InformerGridCard
                      gridItem={card}
                      key={card?.id}
                      sectionName="Recently Viewed"
                    />
                  </div>
                )
              })}
            </div>
          ))}
      </InformerMasterHeader>

      {informerSections.length === 0 && (
        <div className="flex flex-col items-center justify-between py-5">
          <div className="flex w-full justify-center">
            <EmptyResults
              heading="No Sections or Cards match your selected groups."
              subheading="Please try again."
            />
          </div>
          <SearchGroups
            searchVal={searchVal}
            setSearchVal={setSearchVal}
            setSelectedGroups={setSelectedGroups}
            includeSearch={false}
          />
        </div>
      )}
      {informerSections.length > 0 && (
        <>
          {informerSections.map((section, index) => {
            return (
              <div
                className="my-5 border-b border-gray-200 py-2 md:pb-6"
                key={index}
              >
                <div className="flex items-center justify-between pb-5">
                  <p
                    className="text-lg text-gray-500"
                    data-item="section-title"
                  >
                    {section.name}
                  </p>
                  {index === 0 && (
                    <SearchGroups
                      searchVal={searchVal}
                      setSearchVal={setSearchVal}
                      setSelectedGroups={setSelectedGroups}
                      includeSearch={false}
                    />
                  )}
                </div>

                {isMobile ? (
                  <Grid container spacing={2} alignItems="stretch">
                    {section?.cards.map((card) => {
                      return (
                        <Grid
                          size={{ xs: 4 }}
                          key={card?.id}
                          alignItems="stretch"
                        >
                          <InformerGridCard
                            gridItem={card}
                            key={card?.id}
                            sectionName={section.name}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                ) : (
                  <div
                    className={'grid gap-6'}
                    style={{
                      gridTemplateColumns: `repeat(auto-fill, minmax(${
                        isTablet ? '150px' : '180px'
                      }, 1fr))`,
                    }}
                  >
                    {section?.cards.map((card) => {
                      return (
                        <InformerGridCard
                          gridItem={card}
                          key={card?.id}
                          sectionName={section.name}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}
